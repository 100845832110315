// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-30-off-3-tsx": () => import("./../../../src/pages/30-off-3.tsx" /* webpackChunkName: "component---src-pages-30-off-3-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-signin-tsx": () => import("./../../../src/pages/account/signin.tsx" /* webpackChunkName: "component---src-pages-account-signin-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-backdoor-tsx": () => import("./../../../src/pages/backdoor.tsx" /* webpackChunkName: "component---src-pages-backdoor-tsx" */),
  "component---src-pages-bloodtestguide-tsx": () => import("./../../../src/pages/bloodtestguide.tsx" /* webpackChunkName: "component---src-pages-bloodtestguide-tsx" */),
  "component---src-pages-checkout-confirmation-tsx": () => import("./../../../src/pages/checkout/confirmation.tsx" /* webpackChunkName: "component---src-pages-checkout-confirmation-tsx" */),
  "component---src-pages-compare-personalised-products-tsx": () => import("./../../../src/pages/compare-personalised-products.tsx" /* webpackChunkName: "component---src-pages-compare-personalised-products-tsx" */),
  "component---src-pages-consultation-privacy-agreement-tsx": () => import("./../../../src/pages/consultation-privacy-agreement.tsx" /* webpackChunkName: "component---src-pages-consultation-privacy-agreement-tsx" */),
  "component---src-pages-consultation-tsx": () => import("./../../../src/pages/consultation.tsx" /* webpackChunkName: "component---src-pages-consultation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-essential-one-free-trial-tsx": () => import("./../../../src/pages/essential-one-free-trial.tsx" /* webpackChunkName: "component---src-pages-essential-one-free-trial-tsx" */),
  "component---src-pages-essential-one-trial-20-off-tsx": () => import("./../../../src/pages/essential-one-trial-20off.tsx" /* webpackChunkName: "component---src-pages-essential-one-trial-20-off-tsx" */),
  "component---src-pages-facebook-20-tsx": () => import("./../../../src/pages/facebook20.tsx" /* webpackChunkName: "component---src-pages-facebook-20-tsx" */),
  "component---src-pages-facebook-free-tsx": () => import("./../../../src/pages/facebook-free.tsx" /* webpackChunkName: "component---src-pages-facebook-free-tsx" */),
  "component---src-pages-fb-2-week-trial-tsx": () => import("./../../../src/pages/FB2week-trial.tsx" /* webpackChunkName: "component---src-pages-fb-2-week-trial-tsx" */),
  "component---src-pages-ga-2-week-trial-tsx": () => import("./../../../src/pages/GA2week-trial.tsx" /* webpackChunkName: "component---src-pages-ga-2-week-trial-tsx" */),
  "component---src-pages-google-20-tsx": () => import("./../../../src/pages/google20.tsx" /* webpackChunkName: "component---src-pages-google-20-tsx" */),
  "component---src-pages-google-free-tsx": () => import("./../../../src/pages/google-free.tsx" /* webpackChunkName: "component---src-pages-google-free-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kit-tsx": () => import("./../../../src/pages/kit.tsx" /* webpackChunkName: "component---src-pages-kit-tsx" */),
  "component---src-pages-login-magic-tsx": () => import("./../../../src/pages/login/magic.tsx" /* webpackChunkName: "component---src-pages-login-magic-tsx" */),
  "component---src-pages-lp-2-30-3-tsx": () => import("./../../../src/pages/lp/2-30-3.tsx" /* webpackChunkName: "component---src-pages-lp-2-30-3-tsx" */),
  "component---src-pages-lp-2-50-tsx": () => import("./../../../src/pages/lp/2-50.tsx" /* webpackChunkName: "component---src-pages-lp-2-50-tsx" */),
  "component---src-pages-lp-2-free-tsx": () => import("./../../../src/pages/lp/2-free.tsx" /* webpackChunkName: "component---src-pages-lp-2-free-tsx" */),
  "component---src-pages-lp-3-30-3-tsx": () => import("./../../../src/pages/lp/3-30-3.tsx" /* webpackChunkName: "component---src-pages-lp-3-30-3-tsx" */),
  "component---src-pages-lp-3-50-tsx": () => import("./../../../src/pages/lp/3-50.tsx" /* webpackChunkName: "component---src-pages-lp-3-50-tsx" */),
  "component---src-pages-lp-3-55-tsx": () => import("./../../../src/pages/lp/3-55.tsx" /* webpackChunkName: "component---src-pages-lp-3-55-tsx" */),
  "component---src-pages-lp-3-70-tsx": () => import("./../../../src/pages/lp/3-70.tsx" /* webpackChunkName: "component---src-pages-lp-3-70-tsx" */),
  "component---src-pages-lp-3-free-tsx": () => import("./../../../src/pages/lp/3-free.tsx" /* webpackChunkName: "component---src-pages-lp-3-free-tsx" */),
  "component---src-pages-personalised-free-trial-tsx": () => import("./../../../src/pages/personalised-free-trial.tsx" /* webpackChunkName: "component---src-pages-personalised-free-trial-tsx" */),
  "component---src-pages-personalised-plus-2-week-trial-tsx": () => import("./../../../src/pages/personalised-plus-2week-trial.tsx" /* webpackChunkName: "component---src-pages-personalised-plus-2-week-trial-tsx" */),
  "component---src-pages-practitioners-tsx": () => import("./../../../src/pages/practitioners.tsx" /* webpackChunkName: "component---src-pages-practitioners-tsx" */),
  "component---src-pages-product-blood-tsx": () => import("./../../../src/pages/product/blood.tsx" /* webpackChunkName: "component---src-pages-product-blood-tsx" */),
  "component---src-pages-product-bottle-tsx": () => import("./../../../src/pages/product/bottle.tsx" /* webpackChunkName: "component---src-pages-product-bottle-tsx" */),
  "component---src-pages-product-dna-tsx": () => import("./../../../src/pages/product/dna.tsx" /* webpackChunkName: "component---src-pages-product-dna-tsx" */),
  "component---src-pages-product-essential-one-tsx": () => import("./../../../src/pages/product/essential-one.tsx" /* webpackChunkName: "component---src-pages-product-essential-one-tsx" */),
  "component---src-pages-product-gift-card-tsx": () => import("./../../../src/pages/product/gift-card.tsx" /* webpackChunkName: "component---src-pages-product-gift-card-tsx" */),
  "component---src-pages-product-kids-multivitamins-tsx": () => import("./../../../src/pages/product/kids-multivitamins.tsx" /* webpackChunkName: "component---src-pages-product-kids-multivitamins-tsx" */),
  "component---src-pages-product-mind-and-body-tsx": () => import("./../../../src/pages/product/mind-and-body.tsx" /* webpackChunkName: "component---src-pages-product-mind-and-body-tsx" */),
  "component---src-pages-product-nutritionist-consultation-tsx": () => import("./../../../src/pages/product/nutritionist-consultation.tsx" /* webpackChunkName: "component---src-pages-product-nutritionist-consultation-tsx" */),
  "component---src-pages-product-personalised-tsx": () => import("./../../../src/pages/product/personalised.tsx" /* webpackChunkName: "component---src-pages-product-personalised-tsx" */),
  "component---src-pages-products-bars-tsx": () => import("./../../../src/pages/products/bars.tsx" /* webpackChunkName: "component---src-pages-products-bars-tsx" */),
  "component---src-pages-products-bundles-tsx": () => import("./../../../src/pages/products/bundles.tsx" /* webpackChunkName: "component---src-pages-products-bundles-tsx" */),
  "component---src-pages-products-health-goal-bundles-tsx": () => import("./../../../src/pages/products/health-goal-bundles.tsx" /* webpackChunkName: "component---src-pages-products-health-goal-bundles-tsx" */),
  "component---src-pages-products-health-goals-tsx": () => import("./../../../src/pages/products/health-goals.tsx" /* webpackChunkName: "component---src-pages-products-health-goals-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-products-vitamins-tsx": () => import("./../../../src/pages/products/vitamins.tsx" /* webpackChunkName: "component---src-pages-products-vitamins-tsx" */),
  "component---src-pages-products-womens-health-tsx": () => import("./../../../src/pages/products/womens-health.tsx" /* webpackChunkName: "component---src-pages-products-womens-health-tsx" */),
  "component---src-pages-recycle-tsx": () => import("./../../../src/pages/recycle.tsx" /* webpackChunkName: "component---src-pages-recycle-tsx" */),
  "component---src-pages-refer-friend-tsx": () => import("./../../../src/pages/refer-friend.tsx" /* webpackChunkName: "component---src-pages-refer-friend-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-science-tsx": () => import("./../../../src/pages/science.tsx" /* webpackChunkName: "component---src-pages-science-tsx" */),
  "component---src-pages-tik-tok-2-week-trial-tsx": () => import("./../../../src/pages/TikTok2week-trial.tsx" /* webpackChunkName: "component---src-pages-tik-tok-2-week-trial-tsx" */),
  "component---src-pages-tiktok-free-tsx": () => import("./../../../src/pages/tiktok-free.tsx" /* webpackChunkName: "component---src-pages-tiktok-free-tsx" */),
  "component---src-pages-tube-tsx": () => import("./../../../src/pages/tube.tsx" /* webpackChunkName: "component---src-pages-tube-tsx" */),
  "component---src-templates-bars-page-tsx": () => import("./../../../src/templates/BarsPage.tsx" /* webpackChunkName: "component---src-templates-bars-page-tsx" */),
  "component---src-templates-campaign-landing-page-campaign-landing-page-tsx": () => import("./../../../src/templates/campaign-landing-page/CampaignLandingPage.tsx" /* webpackChunkName: "component---src-templates-campaign-landing-page-campaign-landing-page-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/generic-page.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-health-goal-tsx": () => import("./../../../src/templates/health-goal.tsx" /* webpackChunkName: "component---src-templates-health-goal-tsx" */),
  "component---src-templates-new-product-template-tsx": () => import("./../../../src/templates/NewProductTemplate.tsx" /* webpackChunkName: "component---src-templates-new-product-template-tsx" */),
  "component---src-templates-shop-tsx": () => import("./../../../src/templates/shop.tsx" /* webpackChunkName: "component---src-templates-shop-tsx" */),
  "component---src-templates-womens-health-product-tsx": () => import("./../../../src/templates/womens-health-product.tsx" /* webpackChunkName: "component---src-templates-womens-health-product-tsx" */)
}

